@import 'styles/config';

.checkbox {
  input[type='checkbox'],
  input[type='radio'] {
    --active: #{$color-blue};
    --active-inner: #fff;
    --input-border: #cdd9ed;
    --input-border-hover: #{lighten($color-blue, 5%)};
    --background: #fff;
    --disabled: #f5f9ff;
    --disabled-inner: #e4ecfa;
    --shadow-inner: rgba(18, 22, 33, 0.1);
    height: 21px;
    outline: none;
    position: relative;
    top: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0 5px 0 0;
    padding: 0;
    box-shadow: none;
    cursor: pointer;
    border: 1px solid var(--input-border);
    background: var(--background);
    transition: background 0.3s ease, border-color 0.3s ease;

    &:after {
      content: '';
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform 0.3s ease, opacity 0.2s ease, filter 0.3s ease;
    }

    &:checked {
      background: var(--active);
      border-color: var(--active);

      &:after {
        filter: drop-shadow(0 1px 2px var(--shadow-inner));
        transition: opacity 0.3s ease, filter 0.3s ease, transform 0.6s cubic-bezier(0.175, 0.88, 0.32, 1.2);
      }
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.9;
      background: var(--disabled);

      &:checked {
        background: var(--disabled-inner);
        border-color: var(--input-border);
      }
    }

    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          border-color: var(--input-border-hover);
        }
      }
    }
    &:not(.switch) {
      width: 21px;
      &:after {
        opacity: 0;
      }
      &:checked {
        &:after {
          opacity: 1;
        }
      }
    }
  }
  input[type='checkbox'] {
    &:not(.switch) {
      border-radius: 6px;

      &:after {
        width: 5px;
        height: 9px;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 7px;
        top: 4px;
        transform: rotate(20deg);
      }

      &:checked {
        &:after {
          transform: rotate(43deg);
        }
      }
    }

    &.switch {
      width: 38px;
      border-radius: 11px;

      &:after {
        left: 2px;
        top: 2px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        background: var(--input-border);
      }

      &:checked {
        &:after {
          background: var(--active-inner);
          transform: translateX(17px);
        }
      }

      &:disabled {
        &:not(:checked) {
          &:after {
            opacity: 0.8;
          }
        }
      }
    }
  }
}
