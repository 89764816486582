@import 'styles/config';

.stepsContainer {
  background-color: #f2f2f2;
}

.stepsRow {
  padding: 60px 0;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}

.step {
  text-align: center;
  margin-bottom: $gutter * 2;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }

  h4 {
    background-color: $color-yellow;
    color: $color-white;
    text-align: center;
    font-size: 24px;
    width: 70px;
    height: 70px;
    line-height: 70px;
    border-radius: 35px;
    margin: 0 auto $gutter;
    box-shadow: $box-shadow-lg;
  }

  .text {
    font-size: 20px;
  }
}
