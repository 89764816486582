@import 'styles/config';

.formContainer {
  padding: 50px $gutter $gutter;
  border-top: 1px solid darken($color-gray, 10%);
  text-align: left;

  input[type='text'],
  input[type='email'],
  select {
    height: 50px;
    line-height: 50px;
    padding: 0 15px;
  }
}
