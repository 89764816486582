body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-size: 20px;
}

.text-title {
  font-size: 20px;
  font-weight: 700;

  @media (min-width: 768px) {
    font-size: 24px;
  }
}

.text-big {
  font-size: 30px;
  line-height: 1.15;
  font-weight: 700;

  @media (min-width: 768px) {
    font-size: 40px;
  }
}
