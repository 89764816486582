/**
 * ----------------------------------------
 * Color palette
 * ----------------------------------------
 */

$color-white: #ffffff;
$color-black: #2f2f2f;
$color-primary: #1d396e;
$color-primary-dark: #003976;
$color-gray: #f1f4f6;
$color-gray-dark: #4a4a4a;
$color-gray-light: #f9fafb;
$color-green: #6ee585;
$color-green-dark: #399a4b;
$color-green-light: #e9f9ec;
$color-blue: #006add;
$color-blue-light: #dcefff;
$color-blue-medium: #c8dfec;
$color-red: #ff6363;
$color-red-light: #ffdcdc;
$color-yellow: #f1c40f;
$color-yellow-light: #fff7d6;

/**
 * ----------------------------------------
 * Typography
 * ----------------------------------------
 */

$color-text: #2f2f2f;
$color-text-light: #9e9e9e;

/**
 * ----------------------------------------
 * Gutter
 * ----------------------------------------
 */

$gutter: 15px;

/**
 * ----------------------------------------
 * Background
 * ----------------------------------------
 */

%background-color-white {
  background-color: $color-white;
}
%background-color-black {
  background-color: $color-black;
}
%background-color-primary {
  background-color: $color-primary;
}
%background-color-gray-dark {
  background-color: $color-gray-dark;
}
%background-color-green {
  background-color: $color-green;
}
%background-color-blue {
  background-color: $color-blue;
}
%background-color-red {
  background-color: $color-red;
}
%background-color-default {
  background-color: $color-black;
}

/**
 * ----------------------------------------
 * Box shadow
 * ----------------------------------------
 */

$box-shadow-sm: 0 0 20px 0 rgba(0, 0, 0, 0.1);
$box-shadow-md: 0 0 30px 0 rgba(0, 0, 0, 0.1);
$box-shadow-lg: 0 0 40px 0 rgba(0, 0, 0, 0.1);

$box-shadow-card: $box-shadow-sm;
$box-shadow-btn: $box-shadow-md;
$box-shadow-input: $box-shadow-md;
$box-shadow-modal: $box-shadow-lg;

%box-shadow-md {
  box-shadow: $box-shadow-md;
}
%box-shadow-lg {
  box-shadow: $box-shadow-lg;
}
%box-shadow-btn {
  box-shadow: $box-shadow-btn;
}
%box-shadow-modal {
  box-shadow: $box-shadow-modal;
}

/**
 * ----------------------------------------
 * Border-radius
 * ----------------------------------------
 */

$border-radius-sm: 2px;
$border-radius-md: 4px;
$border-radius-lg: 8px;

/**
 * ----------------------------------------
 * Border
 * ----------------------------------------
 */

$color-border: $color-gray;

/**
 * ----------------------------------------
 * Button
 * ----------------------------------------
 */

$button-height-default: 40px;
$button-height-small: 30px;
$button-height-tiny: 25px;

$button-color-default: $color-gray-dark;
$button-color-blue: $color-blue;
$button-color-green: $color-green;
$button-color-red: $color-red;

/**
 * ----------------------------------------
 * Inputs
 * ----------------------------------------
 */

$input-border-color: #cccccc;
$input-focus-color: $color-blue;

/**
 * ----------------------------------------
 * Card
 * ----------------------------------------
 */

$card-border-radius: $border-radius-md;
