@import 'styles/config';

/**
 * ----------------------------------------
 * Available colors, sizes and modes
 * ----------------------------------------
 */

$colors: (default, primary, transparent, red, blue, green, white);
$sizes: (20, 25, 30, 35, 40, 45, 50);

/**
 * ----------------------------------------
 * Mixins
 * ----------------------------------------
 */

@mixin set-font-size($size) {
  @if $size < 40 {
    font-size: 12px;
  } @else {
    font-size: 14px;
  }
}

/**
 * ----------------------------------------
 * Generate button style
 * ----------------------------------------
 */

@each $color in $colors {
  @each $size in $sizes {
    .button-#{$size}-#{$color} {
      position: relative;
      display: inline-block;
      height: #{$size}px;
      line-height: #{$size}px;
      text-align: left;
      font-weight: bold;
      user-select: none;
      border: 0;
      padding: 0 20px;
      transition: all 0.2s ease;
      min-width: 200px;
      cursor: pointer;

      @if $color == transparent {
        color: $color-text;
        background-color: transparent;
        box-shadow: none !important;
      } @else if $color == white {
        color: $color-text;
      } @else {
        color: $color-white;
      }

      @if $color != transparent {
        @extend %background-color-#{$color};
      }

      @include set-font-size($size);

      &[disabled] {
        background-color: #d4d4d4;
        box-shadow: none;

        &:hover {
          opacity: 1;
        }
      }

      &:focus {
        outline: none;
      }

      .icon {
        position: absolute;
        top: 0;
        right: $gutter;
        line-height: #{$size}px;
        @include set-font-size($size);
      }
    }
  }
}
