@import 'styles/config';

.banner {
  width: 100%;
  background-color: #f2f2f2;

  .content {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    h1 {
      font-size: 45px;
      margin-bottom: 30px;
      line-height: 1.1;
      height: 150px;

      @media (min-width: 576px) {
        font-size: 60px;
        height: 220px;
      }

      @media (min-width: 768px) {
        font-size: 100px;
        height: 370px;
      }
    }

    p {
      font-size: 18px;
      max-width: 700px;

      @media (min-width: 576px) {
        font-size: 20px;
      }
    }

    .blue {
      color: $color-blue;
      line-height: 1.5;
    }
  }
}

.iconScroll,
.iconScroll:before {
  position: absolute;
  z-index: 200;
  left: 50%;
}
.iconScroll {
  width: 30px;
  height: 50px;
  margin-left: -20px;
  bottom: 20px;
  margin-top: -35px;
  border: 3px solid $color-blue;
  border-radius: 25px;
}

.iconScroll:before {
  content: '';
  width: 8px;
  height: 8px;
  background: $color-blue;
  margin-left: -4px;
  top: 8px;
  border-radius: 4px;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: scroll;
}

@keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(26px);
  }
}

.svg {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  height: 100%;

  @media (min-width: 576px) {
    width: 1200px;
    height: auto;
  }

  @media (min-width: 768px) {
    width: 1920px;
  }

  @media (min-width: 1920px) {
    width: 100%;
  }
}

.st0 {
  display: none;
}
.st1 {
  display: inline;
  fill: #f2f2f2;
}
.st10 {
  fill: #ffffff;
}
.st12 {
  fill: none;
  stroke: #0063bc;
  stroke-width: 5;
  stroke-miterlimit: 10;
}
.st13 {
  fill: #006add;
}
.st14 {
  fill: none;
  stroke: #006add;
  stroke-width: 5;
  stroke-miterlimit: 10;
}
.st15 {
  fill: none;
  stroke: #efd243;
  stroke-width: 5;
  stroke-miterlimit: 10;
}
.st16 {
  fill: #0063bc;
}
.st17 {
  fill: #efd243;
}
