@import 'styles/config';

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  display: flex;
  padding: $gutter * 2;
  z-index: 100;

  .title {
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
  }

  .actions {
    margin-left: auto;
  }
}
