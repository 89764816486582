@import 'styles/config';

.showcase {
  //min-height: calc(100vh);
  margin-top: 5px;

  @media (min-width: 768px) {
    //height: calc(100vh - 300px);
    margin-top: 50px;
    padding: 30px 0;
  }
}

.title {
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: 700;
  color: $color-text;

  @media (min-width: 768px) {
    font-size: 24px;
  }
}

.textIntro {
  color: $color-text;
}

.text {
  font-size: 30px;
  line-height: 1.15;
  font-weight: 700;
  color: $color-text-light;

  @media (min-width: 768px) {
    font-size: 40px;
  }
}

.image {
  // padding: $gutter * 2;
  // background-color: #f7f7f7;
  // border-radius: 15px;

  @media (min-width: 768px) {
    padding: $gutter * 2;
    background-color: #f7f7f7;
    border-radius: 15px;
  }
}
