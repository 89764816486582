@import 'styles/config';

.featureContainer {
  background-color: #f2f2f2;
  //padding: ($gutter * 2) 0;
}

.featureList {
  //padding: 0 $gutter;
}

.feature {
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid #e2e2e2;
  font-size: 20px;
  font-weight: normal;
  color: $color-text;
}

.featureRight {
  margin-left: auto;
  color: $color-blue;
}
